import {
    COMMUNICATION_TYPE,
    OUTPUT_DATE_FORMAT,
    PRODUCT_DISRUPTION_TYPE,
    RECALL_CLASSIFICATIONS,
    RECALL_STATUS,
    PROVIDER_EVENTLISTING_STATUS,
    ANTICIPATED_DURATION
} from '../../../misc/commons'
import DocumentViewModel from '../../../components/part/shared/DocumentViewModel'
import ActionType from '../../../components/part/provider/ActionType'
import delegateService from '../../../services/provider/delegates'
import _ from 'lodash'
export default {
    components: {
        DocumentViewModel,
        ActionType,
    },
    data() {
        return {
            recall_classifications: RECALL_CLASSIFICATIONS,
            recall_status: RECALL_STATUS,
            anticipated_duration: ANTICIPATED_DURATION,
            provider_event_status: PROVIDER_EVENTLISTING_STATUS,
            item: null,
            actions: null,
            delegateDetails: null,
            internalActions: null,
            allActions: null,
            communicationId: null,
            isDelegate: 0,
            cnt: 1,
            organizationid: null,
            delegateId: null,
            providerCommunicationId: null,
            communicationType: '',
            disruption_type: PRODUCT_DISRUPTION_TYPE,
            delegateEmail: null,
            isExpanded: true,
            editMode: true,
            disabled: true,
            isOlderExpanded: false,
            delegate: {
                delegate_id: null,
                first_name: null,
                last_name: null,
                status: null,
            },
            moreLess: 'See Less Information',
            multiCnt: 0,
            headerName: null,
            labels: {},
            recall: COMMUNICATION_TYPE.RECALL,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,

        }
    },
    created() {
        if (
            this.$route &&
      this.$route.query.c &&
      this.$route.query.e &&
      this.$route.query.e &&
      this.$route.query.e
        ) {
            this.communicationId = this.$route.query.e
            this.organizationid = Number(this.$route.query.c)
            this.delegateId = Number(this.$route.query.h)
            this.delegateEmail = this.$route.query.k
            this.isDelegate = this.$route.query.m
            this.orgType = this.$route.query.t
            this.providerCommunicationId = this.$route.query.pr!=undefined?this.$route.query.pr:0
            if (this.isDelegate == 0) {
                this.editMode = false
            }
            this.getDelegateById()
            if (this.$route.query.m == 0) {
                this.getRecallResponseById()
            } else {
                this.getRecallById()
            }
        }
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
        },
        addclick() {
            this.$refs.modal.show()
            this.delegate = {}
        },
        expandMoreLess() {
            if (this.isExpanded == false) this.moreLess = 'See Less Information'
            else this.moreLess = 'See More Information'
        },
        async save() {
            this.getRecallById()
            if (await this.$refs['observer'].validate()) {
                this.delegate.delegate_id = this.delegateId
                this.delegate.status = 'RESPONDED'
                this.updateDelegate()
            }
        },
        saveAsDraft() {
            this.getRecallById()
            this.delegate.delegate_id = this.delegateId
            this.delegate.status = 'DRAFT'
            this.updateDelegate()
        },
        getRecallById() {
            delegateService.getRecallById(this.communicationId, this.organizationid, this.delegateId,this.providerCommunicationId).then((resp) => {
                if (!resp.error) {
                    this.item = resp.data.d
                    this.item.updates.forEach(s => {
                        s.disruptionUpdate = s.disruptionUpdate.replaceAll('ql-align-right', 'text-right').replaceAll('ql-align-center', 'text-center')
                            .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                            .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                            .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8')
                    })
                    this.headerName = resp.data.d.communicationName + ' - ' + resp.data.d.recallsitename
                    this.internalActions = resp.data.d.actions
                    this.communicationType = resp.data.d.communicationType
                    this.labels={
                        information: this.communicationType == this.recall ? 'Recall Information' : 'Advisory Notice : ',
                        type: this.communicationType == this.recall ? 'Recall Type' : 'Type',
                        status: this.communicationType == this.recall ? 'Recall Status' : 'Status',
                        categories: this.communicationType == this.recall ? 'Recall Categories': 'Category',
                        riskToHealth: this.communicationType == this.recall ? 'Risk to Health': 'Alternatives & Conservation Measures',
                        products: this.communicationType == this.recall ? 'Recall Products': 'Affected Products',
                        attachments: this.communicationType == this.recall ? 'Supporting Materials': 'Attachments',
                        response: this.communicationType == this.recall ? 'Recall Response': 'Advisory Response',
                    }
                    for (let i = 0; i < this.internalActions.length; i++) {
                        let List = []
                        if (this.internalActions[i].action_type == 'MultipleChoice') {
                            for (let j = 0;j < this.internalActions[i].actionValues.length;j++) {
                                List.push({value: this.cnt,text: this.internalActions[i].actionValues[j].value,})
                                if (!this.internalActions[i].isAllowOnlyOne) {
                                    this.internalActions[i].response_data.valueMultiChoice.forEach((element) => {
                                        if (element.valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                            this.internalActions[i].multiSelectedValues.push(this.cnt)
                                        }
                                    })
                                } else {
                                    if(this.internalActions[i].response_data.valueMultiChoice.length > 0)
                                    {
                                        if (this.internalActions[i].response_data.valueMultiChoice[0].valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                            this.internalActions[i].singleSelectedValues = this.cnt
                                        }
                                    }
                                }
                                this.cnt++
                            }
                            this.internalActions[i].actionValues = []
                            this.internalActions[i].actionValues = List
                        }
                    }
                    if (
                        this.item.delegatestatus == 'RESPONDED'
                    ) {
                        this.editMode = false
                    }
                }
            })
        },
        getDelegateById() {
            delegateService.getDelegateById(this.delegateId).then((resp) => {
                if (!resp.error) {
                    this.delegateDetails = resp.data.d
                    if (this.delegateDetails.status == 'RESPONDED') {
                        this.editMode = false
                    }
                }
            })
        },
        getRecallResponseById() {
            delegateService
                .getRecallResponseById(
                    this.communicationId,
                    this.organizationid,
                    this.delegateId,
                    this.providerCommunicationId
                )
                .then((resp) => {
                    if (!resp.error) {
                        this.item = resp.data.d
                        this.item.updates.forEach(s => {
                            s.disruptionUpdate = s.disruptionUpdate.replaceAll('ql-align-right', 'text-right').replaceAll('ql-align-center', 'text-center')
                                .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                                .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                                .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8')
                        })
                        this.communicationType = resp.data.d.communicationType
                        this.headerName = resp.data.d.communicationName + ' - ' + resp.data.d.recallsitename
                        this.labels={
                            information: this.communicationType == this.recall ? 'Recall Information' : 'Advisory Notice : ',
                            type: this.communicationType == this.recall ? 'Recall Type' : 'Type',
                            status: this.communicationType == this.recall ? 'Recall Status' : 'Status',
                            categories: this.communicationType == this.recall ? 'Recall Categories': 'Categories',
                            riskToHealth: this.communicationType == this.recall ? 'Risk to Health': 'Alternatives & Conservation Measures',
                            products: this.communicationType == this.recall ? 'Recall Products': 'Affected Products',
                            attachments: this.communicationType == this.recall ? 'Supporting Materials': 'Attachments',
                            response: this.communicationType == this.recall ? 'Recall Response': 'Advisory Response',
                        }
                        if (this.item.status == 'CLOSED') {
                            this.editMode = false
                        }
                    }
                })
        },
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        },
        updateDelegate() {
            if (this.item.status == 'Completed') {
                this._showToast(this.item.communicationType == this.recall ? 'Cannot modify actions as recall is marked completed' : 'Cannot modify actions as advisory is marked completed', {
                    variant: 'danger',
                })
            } else {
                this.allActions = this.internalActions
                delegateService
                    .updateRecallActions(
                        this.communicationId,
                        this.item.communicationType,
                        this.organizationid,
                        this.delegateId,
                        this.allActions
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            delegateService.updateDelegate(this.delegate, this.item.communicationType).then((resp) => {
                                if (!resp.error) {
                                    this.$refs.modal.hide()
                                    this._showToast('Successfully Updated!', {
                                        variant: 'success',
                                    })
                                    this.$router.go()
                                }
                            })
                        }
                    })
            }
        },
        openLink(link) {
            window.open(link, '_blank')
        }
    },
}
